<script setup lang="ts">
const props = defineProps({
  /** 当前数值 */
  value: Number,
  /** 最小值 */
  min: Number,
  /** 最大值 */
  max: Number,
  /** 高度 */
  height: {
    type: Number,
    default: 31
  },
  fontSize: {
    type: Number,
    default: 17
  },
  /** 背景色 */
  backgroundColor: {
    type: String,
    default: '#F0F0F0'
  },
  /** 前景色 */
  color: {
    type: String,
    default: '#000000'
  },
  disabled: {
    type: Boolean,
  }
});

const emits = defineEmits(['update:value']);

const inputValue = computed({
  get() {
    return props.value?.toString() || '0';
  },
  set(newValue: string) {
    let fixedValue = newValue || '0';
    fixedValue = fixedValue.replace(/[^\d]+/g, '');
    if (inputValue.value !== fixedValue) {
      emits('update:value', fixedValue);
    }
  }
});

function changeValue(offset: number) {
    let fixedValue = Number(props.value || 0) + offset;
    if (props.min && props.min > fixedValue) {
      fixedValue = props.min;
    }
    if (props.max && props.max < fixedValue) {
      fixedValue = props.max;
    }
    emits('update:value', fixedValue);
}

</script>
<template>
  <div class="number-input-pad" :style="{ backgroundColor, color, height: `${height}px`, lineHeight: `${height}px` }">
    <div class="number-input-pad-left" v-if="!disabled" @click="changeValue(-1)">-</div>
    <div class="number-input-pad-center">
      <input :style="{ fontSize: `${fontSize}px`, height: `${height}px` }" v-model="inputValue" disabled  @blur="changeValue(0)" />
    </div>
    <div class="number-input-pad-right" v-if="!disabled" @click="changeValue(1)">+</div>
  </div>
</template>
<style lang="scss" scoped>
.number-input-pad {
  min-width: 93px;
  border-radius: 99999px;
  display: inline-flex;
  .number-input-pad-left,
  .number-input-pad-right {
    padding: 0 12px;
  }
  .number-input-pad-center {
    padding: 0;
    &>input {
      text-align: center;
      width: 100%;
      outline: none;
    }
  }
}
</style>